import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { FIREBASE_HOST } from "../firebase";

const MAP_DEFAULT_WIDTH = 400;
const MAP_DEFAULT_HEIGHT = 300;

const buildMapUrl = ({ 
  lat, 
  lng,
  zoom,
  circleRadius,
  width = MAP_DEFAULT_WIDTH,
  height = MAP_DEFAULT_HEIGHT,
}: MapParams): string => `${FIREBASE_HOST}/map?lat=${lat}&lng=${lng}&width=${width}&height=${height}&circleRadius=${circleRadius}`;

interface MapParams {
  lat: number;
  lng: number;
  zoom?: number;
  width?: number;
  height?: number;
  circleRadius?: number;
}

interface MapProps {
  map: MapParams
  title?: string;
}

const Map = (props: MapProps) => {
  const [mapUrl, setMapUrl] = React.useState(buildMapUrl(props.map));
  
  const openMap = async () => {
    const { map: { lat, lng } } = props;
  
    window.open(`http://maps.google.com/maps?q=${lat},${lng}`, "_blank");
  }

  useEffect(() => setMapUrl(buildMapUrl(props.map)), [props.map]);

  return (
    <div>
      {props.title && <h3>{props.title}</h3>}
      <Image className="Map" src={mapUrl} rounded onClick={() => openMap()}/>
    </div>
  );
}

export default Map;
