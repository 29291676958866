import React, { useEffect, useState } from 'react';
import logo from './25.svg';
import './App.css';
import { getConfig, getToken, onMessageListener, updateSettings } from './firebase';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { UserPreferences } from '../../functions/src/models';
import Notification from './components/Notification';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Settings from './components/Settings';
import Map from './components/Map';

function App() {
  const [showToast, setShowToast] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [token, setToken] = useState('');
  const [config, setConfig] = useState<UserPreferences | null>(null);

  useEffect(() => {
    getConfig(token).then((config) => {
      if (config) {
        setConfig(config);
      }
    }); 
  }, [token])

  const getLocalConfig = async (): Promise<UserPreferences | null> => {
    let localConfig = config;
    
    if (!localConfig) {
      localConfig = await getConfig(token);
      setConfig(localConfig);
    }
    
    return localConfig;
  }

  const setNotifications = async (type: 'pokemon' | 'raid', active: boolean): Promise<void> => {
    const localConfig = await getLocalConfig();

    if (localConfig?.notifications) {
      localConfig.notifications[type] = active;

      const success = await updateSettings(token, localConfig);
      onSettingsUpdate(success);
    }
  }

  const setAllNotifications = async (active: boolean): Promise<void> => {
    const localConfig = await getLocalConfig();

    if (localConfig?.notifications) {
      localConfig.notifications.pokemon = active;
      localConfig.notifications.raid = active;

      const success = await updateSettings(token, localConfig);
      onSettingsUpdate(success);
    }
  }
  
  useEffect(() => {
    if (token === '') {
      getToken(setToken).then((currentToken) => {
        if (currentToken) {
          console.log('current token for client: ', currentToken);
          setToken(currentToken);
          updateSettings(currentToken, { notifications: { raid: true, pokemon: true } });
        } else {
          console.log('No registration token available. Request permission to generate one.');
          setToken('');
        }
      });
    }
  }, [token, setToken]);
  
  onMessageListener().then(({ title = '', body = '' }) => {
    setShowToast(true);
    setNotification({ title, body })
    console.log(title, body);
  }).catch(err => console.log('failed: ', err));

  const updateLocation = () => {
    navigator.geolocation.getCurrentPosition(async ({ coords: { latitude, longitude }})  =>{
      const localConfig = await getLocalConfig();

      if (localConfig?.config) {
        localConfig.config.HOME_LAT = latitude;
        localConfig.config.HOME_LON = longitude;

        const success = await updateSettings(token, localConfig);
        onSettingsUpdate(success);
      }
    });
  }

  const copyToken = () => navigator.clipboard.writeText(token);

  const onSettingsUpdate = (success: boolean) => {
    if (success) {
      setNotification({ title: 'Settings Updated!', body: '' })
    } else {
      setNotification({ title: 'Settings Update Failed!', body: '' })
    }
    setShowToast(true);
  }

  return (
    <div className="App">
      <Notification 
        show={showToast}
        content={notification}
        onClose={() => setShowToast(false)}
      ></Notification>
      
      <header className="App-header">
        <img src={logo} className="App-logo m-5" alt="logo" />
      </header>

      <main className="App-main">
        <div className="App-notifications">
          <h2>Notifications</h2>
          <div className="toggles">
            <div className="toggle">
              <label>Toggle All</label>
              <BootstrapSwitchButton onChange={(checked) => setAllNotifications(checked)} checked={config?.notifications.pokemon || config?.notifications.raid} />
            </div>
            <div className="toggle">
              <label>Nearby Pokemon</label>
              <BootstrapSwitchButton onChange={(checked) => setNotifications('pokemon', checked)} checked={config?.notifications.pokemon} />
            </div>
            <div className="toggle">
              <label>Nearby Raids</label>
              <BootstrapSwitchButton onChange={(checked) => setNotifications('raid', checked)} checked={config?.notifications.raid} />
            </div>
          </div>
        </div>

        {config?.config && 
          <div className="App-maps">
            <Map 
              title="Pokemon Area"
              map={{
                lat:config?.config?.HOME_LAT,
                lng: config?.config?.HOME_LON,
                circleRadius: config?.config?.POKEMON_MAX_DISTANCE_KM,
              }}
            />
            <Map 
              title="Raid Area"
              map={{
                lat:config?.config?.HOME_LAT,
                lng: config?.config?.HOME_LON,
                circleRadius: config?.config?.RAID_MAX_DISTANCE_KM,
              }}
            />
          </div>
        }

        <div className="App-buttons">
          <Button onClick={() => updateLocation()}>Update Location</Button>
          <Settings token={token} config={config} setConfig={setConfig} onSave={(success: boolean) => onSettingsUpdate(success)}/>
          <Button onClick={() => copyToken()}>Copy Token</Button>
        </div>

      </main>
    </div>
  );
}

export default App;
