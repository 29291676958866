import firebase from 'firebase/app';
import 'firebase/messaging';

import { UserPreferences } from './../../functions/src/models';

const firebaseConfig = {
  apiKey: "AIzaSyCcWzSurDUOFs7esixBFtnair3I4xuZiTE",
  authDomain: "pokemon-go-monitor-12c4c.firebaseapp.com",
  projectId: "pokemon-go-monitor-12c4c",
  storageBucket: "pokemon-go-monitor-12c4c.appspot.com",
  messagingSenderId: "320673515978",
  appId: "1:320673515978:web:14dacaafe182f830a7a733"
};

firebase.apps.length === 0 && firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

export const FIREBASE_HOST = 'https://us-east4-pokemon-go-monitor-12c4c.cloudfunctions.net';

export const getToken = async (setToken: React.Dispatch<React.SetStateAction<string>>): Promise<string> => 
  messaging.getToken({
    vapidKey: 'BAIGfNyH5GPojjRMjbWi9jKpfkfwER3iBVqXE6Hvh4rPxCgcltALy1ftir5POq0dDDt6hI3pOEvYjSxGntQtwM0'
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    return '';
  });

export const onMessageListener = () =>
  new Promise<firebase.messaging.NotificationPayload>((resolve, reject) => {
    messaging.onMessage((payload: firebase.messaging.MessagePayload) => {
      payload?.notification && resolve(payload.notification);
      reject();
    });
  });

export const getConfig = async (token: string): Promise<UserPreferences | null> => {
  try {
    const response = await fetch(`${FIREBASE_HOST}/settings/${token}`);

    if (response.status < 200 || response.status >= 400) {
      throw new Error('Error getting settings: '+response.status + ' - ' + response.text());
    }

    return response.json();
  } catch(error) {
    console.error(error);
  }
  return null;
}

export const updateSettings = async (token: string, config: UserPreferences): Promise<boolean> => {
  try {
    const response = await fetch(`${FIREBASE_HOST}/settings/${token}`, { 
      method: 'PUT', 
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(config) 
    })
  
    if (response.status < 200 || response.status >= 400) {
      throw new Error('Error updating settings: '+response.status + ' - ' + response.text());
    }
    console.log(`Subscribed to raids and pokemon!`);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}
