import React from 'react';
import {Toast} from 'react-bootstrap';

interface NotificationProps { 
  show: boolean;
  content: { title: string, body: string };
  onClose: () => void;
};

const Notification = ({ 
show, 
  content,
  onClose, 
}: NotificationProps) => (
  <Toast 
    onClose={onClose} 
    show={show} 
    delay={3000} 
    autohide 
    animation 
    style={{
      position: 'absolute',
      top: 20,
      right: 20,
    }}
  >
    <Toast.Header>
      <img
        src="25.png"
        className="rounded mr-2"
        alt=""
      />
      <strong className="mr-auto">{content.title}</strong>
      <small>just now</small>
    </Toast.Header>
    <Toast.Body>{content.body}</Toast.Body>
  </Toast>
);

export default Notification;