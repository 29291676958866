import React, { useEffect, useState } from 'react';
import {Button, FormControl } from 'react-bootstrap';
import { updateSettings } from '../firebase';
import { UserPreferences } from '../../../functions/src/models';
import './Settings.css';

interface SettingsProps {
  token: string;
  config: UserPreferences | null;
  setConfig: (config: UserPreferences) => void;
  onSave: (success: boolean) => void;
}

const Settings = ({
  token,
  config,
  setConfig,
  onSave,
}: SettingsProps) => {
  const [showConfig, setShowConfig] = useState(false);
  const [configString, setConfigString] = useState('');
  const [showSave, setShowSave] = useState(false);
  const [jsonError, setJsonError] = useState(false);

  useEffect(() => {
    if (showConfig) {
      setConfigString(JSON.stringify(config, null, 2));
    }
  }, [config, showConfig]);

  const updateConfig = (configString: string) => {
    setConfigString(configString);
    setShowSave(true);

    try {
      setConfig(JSON.parse(configString));
      setJsonError(false);
    } catch (error) {
      setJsonError(true);
    }
  }

  const saveConfig = async () => {
    setShowSave(false);
    setShowConfig(false);

    if (config) {
      const success = await updateSettings(token, config);
      onSave(success);
    }
  }

  return (
    <section className={`Settings ${showConfig ? 'Settings-open' : ''}`}>
      <Button onClick={() => setShowConfig(!showConfig)}>{ showConfig ? 'Hide Config' : 'Show Config'}</Button>
      {showConfig && 
        <aside>
          <div className="Settings-config-edit">
            {showSave && 
              <Button disabled={jsonError} onClick={() => saveConfig()}>
                {jsonError ? <span className="Settings-config-error">Invalid JSON.</span> : 'Save Changes'}
              </Button>
            }
          </div>
          <FormControl
            className="Settings-config"
            as="textarea"
            value={configString}
            onChange={e => updateConfig(e.target.value)}
          />
        </aside>
}
      </section>
  );
}

export default Settings;
